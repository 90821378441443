import {
  Box,
  Button,
  Chip,
  Dialog,
  FormControl,
  Grid,
  IconButton,
  InputBase,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableRow,
  TextField,
  Typography,
} from '@mui/material';
import SpeedDial, { createAction } from '../../services/speedDial';
import {
  createOrder,
  querySummary,
  updateSummary,
} from '../../fetchers/summary.fetch';
import { phoneMobileMask, ssnMask } from '../../validations/imask';
import { queryUserInteractions, updateUser } from '../../fetchers/system.fetch';
import { useNavigate, useParams } from 'react-router-dom';

import AddEventDialog from './AddEventDialog';
import AddOrderDialog from './AddOrderDialog';
import CloseIcon from '@mui/icons-material/Close';
import { DateTime } from 'luxon';
import Detail from '../../components/Detail';
import { InteractionTable } from './InteractionTable';
import OrderTable from './OrderTable';
import Panel from '../../components/Panel';
import React from 'react';
import ReferralTable from './ReferralTable';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import { blue } from '@mui/material/colors';
import crumbs from '../../services/crumbs/crumbs.const';
import { queryBrokers } from '../../fetchers/broker.fetch';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { ssn } from '../../validations/yupSchemas';
import { toast } from 'react-toastify';
import useAccess from '../../services/authentication/useAccess';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';

const sources = [
  '親友介紹',
  '舊客戶',
  '網路',
  '禮儀公司',
  '自來',
  '里長',
  '其他',
];
const initialStates = {
  event: {
    open: false,
    interactionType: '電話',
    desc: '',
  },
  order: {
    open: false,
    orderType: '一般',
    sn: '',
    broker: null,
    saleAttribution: null,
    commission: null,
  },
};
function ToggleInput({ children }) {
  return (
    <Box
      sx={{
        '.display': 'inline-block',
        '.MuiInputBase-input': {
          padding: '6px 6px 3px 6px',
          position: 'relative',
        },
        '.MuiInputBase-root': {
          '&:before': {
            content: '""',
            position: 'absolute',
            fontSize: '12px',
            top: -5,
            left: 5,
            backgroundColor: 'white',
            lineHeight: 1,
            zIndex: 1,
            color: 'rgba(0,0,0,0.6)',
          },
          '&:nth-of-type(1)': { width: 115 },
          '&:nth-of-type(2)': {
            width: 0,
            ml: 1,
          },
        },
        '&:focus-within': {
          '.MuiInputBase-input': {
            border: '1px solid rgba(0, 0, 0, 0.3)',
            borderRadius: (theme) => theme.shape.borderRadius / 3,
          },
          '.MuiInputBase-root:nth-of-type(1)': {
            '&:before': {
              content: '"姓名"',
            },
          },
          '.MuiInputBase-root:nth-of-type(2)': {
            width: 115,
            '&:before': {
              content: '"身分證字號"',
            },
          },
        },
      }}>
      {children}
    </Box>
  );
}

function SummaryDetail(props) {
  useCrumbs([crumbs.summaryList, crumbs.summaryDetail]);
  const { id } = useParams();
  const user = useUser();
  const navigate = useNavigate();
  const hasAccess = useAccess();
  const [eventDialog, setEventDialog] = React.useState(initialStates.event);
  const [interactionsDialog, setInteractionsDialog] = React.useState(false);
  const [order, setOrder] = React.useState(initialStates.order);
  const [inputs, setInputs] = React.useState({
    name: '',
    phone: '',
    deceased: '',
    ssn: '',
  });
  const summaryQuery = useQuery(['summary', id], querySummary({ id }), {
    onSuccess: (data) => setSummary(data),
  });
  const [summary, setSummary] = React.useState(summaryQuery.data);
  const summaryUpdateRequest = useMutation(updateSummary, {
    onSuccess: () => {
      toast.success('成功儲存追蹤紀錄');
      summaryQuery.refetch();
    },
  });
  const orderCreateRequest = useMutation(createOrder, {
    onSuccess: (data) =>
      navigate(`/order/${data.orders[data.orders.length - 1]}`),
  });

  if (summaryQuery.isLoading || !summary) return <Spinner />;
  if (summaryQuery.isError) return <p>{summaryQuery.error.toString()}</p>;
  const active = ['持續追蹤', '無帶看紀錄'].includes(summary.status);

  function handleReferralLoaded(response) {
    response = response.map((r) => r._id);
    const updated = summary.referrals.filter((r) => response.includes(r));
    if (updated.length !== summary.referrals.length) {
      summaryUpdateRequest.mutate({ id, referrals: updated });
    }
  }
  function handleReferralToggle(id) {
    let updated;
    if (summary.referrals.includes(id)) {
      updated = summary.referrals.filter((r) => id !== r.toString());
    } else {
      updated = summary.referrals.concat(id);
    }
    summaryUpdateRequest.mutate({ id: summary._id, referrals: updated });
  }
  function handleEventCreated() {
    if (
      eventDialog.interactionType === '簽約' &&
      !summary.interactions.some((e) => e.interactionType === '帶看')
    ) {
      return toast.error('沒有帶看紀錄', { autoClose: false });
    }
    summaryUpdateRequest.mutate(
      {
        id,
        interactions: summary.interactions.concat({
          date: Date.now(),
          interactionType: eventDialog.interactionType,
          by: {
            _id: user._id,
            name: user.name,
          },
          desc: eventDialog.desc,
        }),
      },
      {
        onSuccess: () => {
          setEventDialog(initialStates.event);
        },
      }
    );
  }
  function handleOrder(op) {
    switch (op) {
      case 'cancel':
        setOrder({ ...order, open: false });
        break;
      case 'open':
        setOrder({ ...order, open: true });
        break;
      case 'save':
        const payload = {
          sn: order.sn,
          orderType: order.orderType,
          broker: order.broker
            ? { ...order.broker, commission: order.commission }
            : null,
          saleAttribution: order.saleAttribution,
        };
        orderCreateRequest.mutate({ id, ...payload });
        break;
      default:
        return;
    }
  }

  return (
    <Box>
      <Paper elevation={2} sx={{ mt: 2 }}>
        <SummaryInfo
          summary={summary}
          setSummary={setSummary}
          active={active}
          hasAccess={hasAccess}
          inputs={inputs}
          setInputs={setInputs}
        />
      </Paper>
      <Tabs
        sx={{ mt: 2, height: 480 }}
        tabs={[
          {
            title: '追蹤紀錄',
            content: <InteractionTable interactions={summary.interactions} />,
          },
          {
            title: '通報紀錄',
            content: (
              <ReferralTable
                summary={summary}
                onLoaded={handleReferralLoaded}
                onToggle={handleReferralToggle}
              />
            ),
          },
          {
            title: '訂單紀錄',
            hidden: summary.orders?.length === 0,
            content: <OrderTable orders={summary.orders} />,
          },
        ]}
      />
      <AddEventDialog
        state={eventDialog}
        setState={setEventDialog}
        onCreate={handleEventCreated}
        status={summary.status}
      />
      {summary.status === '成交' && (
        <AddOrderDialog
          state={order}
          setState={setOrder}
          summary={summary}
          onOperation={handleOrder}
        />
      )}
      {interactionsDialog && (
        <InteractionPanel
          summary={summary}
          open={interactionsDialog}
          setOpen={setInteractionsDialog}
          summaryUpdateRequest={summaryUpdateRequest}
          user={user}
        />
      )}
      <SpeedDial
        actions={[
          createAction(speedDialIcons.main),
          createAction(
            speedDialIcons.save,
            '儲存',
            () =>
              summaryUpdateRequest.mutate({
                id,
                source: summary.source,
                names: summary.names,
                phones: summary.phones.map((p) => phoneMobileMask.unmask(p)),
                deceaseds: summary.deceaseds,
                referrals: summary.referrals,
              }),
            !active || !hasAccess('Er')
          ),
          createAction(
            speedDialIcons.addToList,
            '新增追蹤紀錄',
            () => setEventDialog((prev) => ({ ...prev, open: true })),
            !hasAccess('S')
          ),
          createAction(
            speedDialIcons.addLink,
            '綁定帶看/簽約行程',
            () => setInteractionsDialog(true),
            summary?.status === '成交' || !hasAccess('Er')
          ),
          createAction(
            speedDialIcons.complete,
            '建立訂單',
            () => handleOrder('open'),
            summary?.status !== '成交' || !hasAccess('ASp')
          ),
        ]}
      />
    </Box>
  );
}

function SummaryInfo({
  summary,
  setSummary,
  active,
  hasAccess,
  inputs,
  setInputs,
}) {
  function handleDelete(field) {
    return (text) => {
      if (field === 'name') {
        setSummary({
          ...summary,
          names: summary.names.filter((name) => name !== text),
        });
      }
      if (field === 'phone') {
        setSummary({
          ...summary,
          phones: summary.phones.filter((phone) => phone !== text),
        });
      }
      if (field === 'deceased') {
        setSummary({
          ...summary,
          deceaseds: summary.deceaseds.filter(
            (deceased) => !text.includes(deceased.name)
          ),
        });
      }
    };
  }
  function handleKeyDown(field) {
    return (event) => {
      if (event.code === 'Enter' || event.code === 'NumpadEnter') {
        if (field === 'name' && inputs.name.length === 0) return;
        if (field === 'phone' && inputs.phone.length === 0) return;
        if (
          (field === 'deceased' || field === 'ssn') &&
          inputs.deceased.length === 0
        )
          return;
        event.preventDefault();
        if (field === 'name') {
          setSummary({
            ...summary,
            names: [...summary.names, inputs['name']],
          });
          setInputs({ ...inputs, name: '' });
        }
        if (field === 'phone') {
          setSummary({
            ...summary,
            phones: [...summary.phones, inputs['phone']],
          });
          setInputs({ ...inputs, phone: '' });
        }
        if (field === 'deceased' || field === 'ssn') {
          if (inputs.ssn.length !== 0 && !ssn.isValidSync(inputs.ssn)) return;
          setSummary({
            ...summary,
            deceaseds: [
              ...summary.deceaseds,
              {
                name: inputs.deceased,
                ssn: inputs.ssn ? inputs.ssn.toUpperCase() : null,
              },
            ],
          });
          setInputs({ ...inputs, deceased: '', ssn: '' });
        }
      }
    };
  }
  if (!summary) return null;
  return (
    <Grid container spacing={3} sx={{ padding: '5px 20px 20px 20px' }}>
      <Grid item xs={12} sm={6}>
        <TextField
          variant="filled"
          label="追蹤識別碼"
          inputProps={{ readOnly: true }}
          value={summary._id}
          fullWidth
        />
      </Grid>
      <Grid item xs={12} sm={6}>
        {summary.orders?.length > 0 ? (
          <TextField
            variant="filled"
            label="訂單識別碼"
            inputProps={{ readOnly: true }}
            value={summary.orders.map((o) => o._id).join(', ')}
            fullWidth
          />
        ) : (
          <div style={{ paddingTop: 15 }}>
            <Detail title="狀態" value={summary.status} />
          </div>
        )}
      </Grid>
      <Grid item xs={12} sm={6}>
        <FormControl variant="outlined" fullWidth>
          <InputLabel>來源</InputLabel>
          <Select
            label="來源"
            value={summary.source}
            onChange={(event) =>
              setSummary({ ...summary, source: event.target.value })
            }
            readOnly={!active || !hasAccess('Er')}>
            {sources.map((source, index) => (
              <MenuItem key={`source-${index}`} value={source}>
                {source}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Panel title="姓名" minHeight={60} padding="10px">
          <Grid container spacing={1} sx={{ pt: 0.5 }}>
            {summary.names.map((name, index) => (
              <Grid key={`n-${index}`} item>
                <Chip
                  sx={{ fontSize: 15 }}
                  label={name}
                  {...(active && hasAccess('Er')
                    ? { onDelete: () => handleDelete('name')(name) }
                    : null)}
                />
              </Grid>
            ))}
            <Grid item>
              <InputBase
                sx={{
                  width: 100,
                }}
                value={inputs.name}
                onChange={(event) =>
                  setInputs({ ...inputs, name: event.target.value })
                }
                onKeyDown={handleKeyDown('name')}
                readOnly={!active || !hasAccess('Er')}
              />
            </Grid>
          </Grid>
        </Panel>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Panel title="電話" minHeight={60} padding="10px">
          <Grid container spacing={1} sx={{ pt: 0.5 }}>
            {summary.phones.map((phone, index) => (
              <Grid key={`p-${index}`} item>
                <Chip
                  sx={{ fontSize: 15 }}
                  label={phone}
                  {...(active && hasAccess('Er')
                    ? { onDelete: () => handleDelete('phone')(phone) }
                    : null)}
                />
              </Grid>
            ))}
            <Grid item>
              <InputBase
                sx={{ width: 110 }}
                value={inputs.phone}
                onChange={(event) => {
                  phoneMobileMask.onChange(event);
                  setInputs({ ...inputs, phone: event.target.value });
                }}
                onKeyDown={handleKeyDown('phone')}
                readOnly={!active || !hasAccess('Er')}
              />
            </Grid>
          </Grid>
        </Panel>
      </Grid>
      <Grid item xs={12} sm={6}>
        <Panel title="歿者" minHeight={60} padding="10px">
          <Grid container spacing={1} sx={{ pt: 0.5 }}>
            {summary.deceaseds.map((deceased, index) => (
              <Grid key={`d-${index}`} item>
                <Chip
                  sx={{ fontSize: 15 }}
                  label={`${deceased.name}${
                    deceased.ssn ? ` (${deceased.ssn})` : ''
                  }`}
                  {...(active && hasAccess('Er')
                    ? {
                        onDelete: () => handleDelete('deceased')(deceased.name),
                      }
                    : null)}
                />
              </Grid>
            ))}
            <Grid item>
              {active && hasAccess('Er') && (
                <ToggleInput>
                  <InputBase
                    value={inputs.deceased}
                    onChange={(event) =>
                      setInputs({ ...inputs, deceased: event.target.value })
                    }
                    onKeyDown={handleKeyDown('deceased')}
                  />
                  <InputBase
                    value={inputs.ssn}
                    onChange={(event) => {
                      event.target.value = event.target.value.toUpperCase();
                      ssnMask.onChange(event);
                      setInputs({
                        ...inputs,
                        ssn: event.target.value,
                      });
                    }}
                    onKeyDown={handleKeyDown('ssn')}
                  />
                </ToggleInput>
              )}
            </Grid>
          </Grid>
        </Panel>
      </Grid>
    </Grid>
  );
}

function InteractionPanel({ summary, open, setOpen, summaryUpdateRequest }) {
  const [state, setState] = React.useState({
    broker: null,
    action: null,
    user: null,
    keyword: '',
    input: '',
  });
  const brokersQuery = useQuery(
    ['brokers', { keyword: state.keyword }],
    queryBrokers(state.keyword),
    {
      enabled: !!state.keyword,
      placeholder: [],
    }
  );
  const usersInteractionsQuery = useQuery(
    ['users', 'interactions'],
    queryUserInteractions,
    {
      staleTime: 0,
    }
  );
  const userUpdateRequest = useMutation(updateUser);

  function onKeyDown(event) {
    if (event.code === 'Enter' || event.code === 'NumpadEnter') {
      if (state.input.length === 0) return;
      event.preventDefault();
      setState({ ...state, keyword: event.target.value, input: '' });
    }
  }
  function handleSave() {
    summaryUpdateRequest.mutate(
      {
        id: summary._id,
        interactions: summary.interactions.concat({
          ...state.action,
          broker: state.broker,
        }),
      },
      {
        onSuccess: () => {
          if (
            state.action.interactionType !== '簽約' ||
            (state.action.interactionType === '簽約' && state.action.end)
          ) {
            userUpdateRequest.mutate(
              {
                id: state.user._id,
                interactions: state.user.interactions.filter(
                  (i) => i._id.toString() !== state.action._id.toString()
                ),
              },
              {
                onSuccess: () => {
                  setState({ open: false });
                  setOpen(false);
                },
              }
            );
          } else {
            setState({ open: false });
            setOpen(false);
          }
        },
      }
    );
  }
  if (!open) return null;

  const noData =
    usersInteractionsQuery.isSuccess &&
    usersInteractionsQuery.data.length === 0;

  return (
    <Dialog
      open={true}
      maxWidth={state.action || noData ? 'sm' : 'lg'}
      fullWidth>
      <Grid container spacing={3} sx={{ p: 2 }}>
        <Grid item xs={12} sm={state.action || noData ? 6 : 8}>
          <Box>
            {noData && <p>沒有可綁定的行程</p>}
            {usersInteractionsQuery.isSuccess &&
              !state.action &&
              !noData &&
              usersInteractionsQuery.data.map((user) => (
                <div key={user._id}>
                  <Typography
                    sx={{ fontSize: 18, py: 0.6, px: 2, bgcolor: blue[50] }}>
                    {user.name}
                  </Typography>
                  <Table>
                    <TableBody>
                      {user.interactions
                        .filter(
                          (action) =>
                            !!action.end || action.interactionType === '簽約'
                        )
                        .map((action) => (
                          <TableRow
                            key={action._id}
                            onDoubleClick={() => {
                              const processed = {
                                ...action,
                                date: DateTime.fromISO(action.date),
                                end: action.end
                                  ? DateTime.fromISO(action.end)
                                  : null,
                              };
                              setState({
                                ...state,
                                user: user,
                                action: processed,
                              });
                            }}>
                            <TableCell>{action.date?.toDate()}</TableCell>
                            <TableCell>{action.interactionType}</TableCell>
                            <TableCell>
                              {DateTime.fromISO(action.date).toFormat('HH:mm')}~
                              {action.end
                                ? DateTime.fromISO(action.end).toFormat('HH:mm')
                                : '尚未結束'}
                            </TableCell>
                            <TableCell>
                              {action.products.map((p) => p.sn).join('、')}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </div>
              ))}
            {state.action && (
              <>
                <Grid
                  container
                  justifyContent="space-between"
                  alignItems="center">
                  <Grid item>
                    <Typography sx={{ fontSize: 18, py: 0.6, px: 2 }}>
                      行程內容
                    </Typography>
                  </Grid>
                  <Grid item>
                    <IconButton
                      onClick={() => setState({ ...state, action: null })}>
                      <CloseIcon />
                    </IconButton>
                  </Grid>
                </Grid>
                <Box sx={{ p: 2, pt: 3 }}>
                  <Detail
                    width="30%"
                    title="日期"
                    value={state.action.date.toFormat('yyyy-MM-dd')}
                  />
                  <Detail
                    width="30%"
                    title="時間"
                    value={`${state.action.date.toFormat('HH:mm')}~${
                      state.action.end
                        ? state.action.end.toFormat('HH:mm')
                        : '尚未結束'
                    }`}
                  />
                  <Detail
                    width="30%"
                    title="類別"
                    value={state.action.interactionType}
                  />
                  <Detail
                    width="30%"
                    title="產品"
                    value={state.action.products.map((p) => p.sn).join('、')}
                  />
                </Box>
              </>
            )}
          </Box>
        </Grid>
        <Grid item xs={12} md={state.action || noData ? 6 : 4}>
          {!state.broker ? (
            <>
              <TextField
                variant="outlined"
                size="small"
                placeholder="查詢仲介來源..."
                value={state.input}
                onChange={(e) => setState({ ...state, input: e.target.value })}
                onKeyDown={onKeyDown}
              />
              {brokersQuery.isSuccess && !state.broker && (
                <Table>
                  <TableBody>
                    {brokersQuery.data.map((broker) => (
                      <TableRow
                        key={broker._id}
                        onDoubleClick={() => setState({ ...state, broker })}>
                        <TableCell>{broker.name}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              )}
            </>
          ) : (
            <>
              <Grid
                container
                justifyContent="space-between"
                alignItems="center">
                <Grid item>
                  <Typography sx={{ fontSize: 18, py: 0.6, px: 2 }}>
                    隨行禮儀
                  </Typography>
                </Grid>
                <Grid item>
                  <IconButton
                    onClick={() => setState({ ...state, broker: null })}>
                    <CloseIcon />
                  </IconButton>
                </Grid>
              </Grid>
              <Box sx={{ p: 2, pt: 3 }}>
                <Detail width="30%" title="簡稱" value={state.broker.name} />
                <Detail
                  width="30%"
                  title="公司"
                  value={state.broker.company?.name}
                />
              </Box>
            </>
          )}
        </Grid>
      </Grid>
      <div style={{ textAlign: 'right', padding: 20 }}>
        <Button
          variant="contained"
          color="secondary"
          onClick={() => {
            setOpen(false);
            setState({ open: false });
          }}>
          取消
        </Button>
        {!noData && state.action && (
          <Button
            variant="contained"
            color="primary"
            sx={{ ml: 2 }}
            onClick={handleSave}>
            儲存
          </Button>
        )}
      </div>
    </Dialog>
  );
}

export default SummaryDetail;
