import AddTaggedPaymentDialog, {
  addTaggedPaymentDialogReducer,
} from '../../components/AddTaggedPaymentDialog';
import {
  Button,
  Dialog,
  DialogTitle,
  Drawer,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@mui/material';
import DeceasedInfoPanel, {
  deceasedInfoPanelReducer,
} from '../../components/DeceasedInfoPanel';
import SpeedDial, { createAction } from '../../services/speedDial';
import { queryProductById, updateProduct } from '../../fetchers/product.fetch';

import AddContactDialog from '../../components/AddContactDialog';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { DateTime } from 'luxon';
import Detail from '../../components/Detail';
import FileTable from '../../components/FileTable';
import Memos from '../../components/Memos';
import PaymentTable from '../../components/PaymentTable';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import PrintableCert from '../../components/PrintableCert';
import PrintableDialog from '../../components/PrintableDialog';
import ProductInfo from './ProductInfo';
import QuoteTable from '../../components/QuoteTable';
import React from 'react';
import Spinner from '../../components/Spinner';
import Tabs from '../../components/Tabs';
import { createFile } from '../../fetchers/file.fetch';
import crumbs from '../../services/crumbs/crumbs.const';
import speedDialIcons from '../../services/speedDial/speedDialIcons';
import { toast } from 'react-toastify';
import useCrumbs from '../../services/crumbs/useCrumbs';
import useMutation from '../../services/httpClient/useMutation';
import { useParams } from 'react-router-dom';
import useQuery from '../../services/httpClient/useQuery';
import { useSetMyFilesTag } from '../../components/FileTable/useMyFiles';

export function lotMessage(lot) {
  let message = '';
  if (lot.sn) {
    message += lot.sn;
  }
  if (lot.size) {
    message += ` (${lot.size}`;
    if (!lot.size.includes('/')) {
      message += '坪';
    }
    message += ')';
  }
  return message;
}

function ProductDetail(props) {
  useCrumbs([crumbs.productList, crumbs.productDetail]);
  const { id } = useParams();
  useSetMyFilesTag(id);
  const [sidebar, setSidebar] = React.useState({ open: false });
  const [contactDialog, setContactDialog] = React.useState(false);
  const [cert, setCert] = React.useState(false);
  const selected = React.useState(0);
  const [fileFlag, setFileFlag] = React.useState(Date.now());
  const [ownerDialog, setOwnerDialog] = React.useState({ open: false });
  const [paymentDialog, paymentDialogDispatch] = React.useReducer(
    addTaggedPaymentDialogReducer,
    { open: false, refresh: Date.now() }
  );
  const deceasedDialog = React.useReducer(deceasedInfoPanelReducer, {
    deceased: null,
    refresh: Date.now(),
  });
  const productQuery = useQuery(
    ['product', id],
    queryProductById({ id, deceaseds: true, contacts: true })
  );
  const productUpdateRequest = useMutation(updateProduct, {
    onSuccess: () => {
      toast.success('成功儲存產品資料');
      productQuery.refetch();
    },
  });
  React.useEffect(() => {
    productQuery.refetch();
  }, [deceasedDialog[0].refresh]);
  React.useEffect(() => {
    if (!productQuery.data) return;
    const setSelected = selected[1];
    if (!productQuery.data.contacts.some((x) => x.contactType === '權益人')) {
      if (productQuery.data.contacts.some((x) => x.contactType === '被授權人'))
        return setSelected(1);
      if (productQuery.data.contacts.some((x) => x.contactType === '聯絡人'))
        return setSelected(2);
      if (productQuery.data.deceaseds.some((x) => x)) return setSelected(3);
    }
  }, [productQuery.data?._id]);

  if (productQuery.isLoading) return <Spinner />;
  const product = productQuery.data;

  const contactFilter = {
    owners: product.contacts.filter(
      (contact) => contact.contactType === '權益人'
    ),
    poas: product.contacts.filter(
      (contact) => contact.contactType === '被授權人'
    ),
    contacts: product.contacts.filter(
      (contact) => contact.contactType === '聯絡人'
    ),
  };
  const deceasedFilter = {
    hide: product.deceaseds.filter(
      (deceased) =>
        !deceased.moveout ||
        DateTime.fromISO(deceased.moveout) > DateTime.now().startOf('month')
    ),
    get hasHidden() {
      return this.hide.length !== product.deceaseds.length;
    },
  };
  function handleContact(response) {
    if (product.contacts.find((x) => x._id === response._id)) {
      return '重複客戶資料';
    }
    const updated = [...product.contacts, response];
    productUpdateRequest.mutate({ id, contacts: updated });
  }
  function handleDeleteContact(response) {
    const updated = product.contacts.filter(
      (contact) =>
        contact._id !== response._id || contact.contactType === '權益人'
    );
    productUpdateRequest.mutate({ id, contacts: updated });
  }
  function handlePaymentsLoaded(response) {
    if (response.strata) {
      if (product.area === '永生墓園' && product.strata.status === '缺繳') {
        if (!product.strata.fee) return console.log('no fee');

        const filtered = response.strata.filter((payment) =>
          /^年繳/.test(payment.desc)
        );
        let years = [];

        for (let payment of filtered) {
          let matches = payment.desc.matchAll(/\d{4}/g);
          let count = 0;
          let candidates = [];

          for (let match of matches) {
            candidates.push(match[0]);
            count += 1;
          }
          if ((product.strata.fee / 10) * count === payment.amount) {
            years = years.concat(candidates);
          }
        }
        for (let year = 2010; year < DateTime.now().year; year++) {
          if (!years.includes(year.toString()))
            return console.log(`missing ${year}`);
        }
        if (DateTime.now().month > 5) {
          return console.log(`missing ${DateTime.now().year}`);
        }
        const updated = {
          ...product.strata,
          status: '年繳',
        };
        productUpdateRequest
          .mutateAsync({ id, strata: updated })
          .then(() => {
            toast.success('管理費更新為年繳', {
              autoClose: 7000,
            });
            productQuery.refetch();
            return true;
          })
          .catch((err) => {
            toast.error(err, { autoClose: false });
            return false;
          });
      }
      if (
        product.area === '頂福陵園' ||
        (product.area === '永生墓園' &&
          (product.strata.status === '未繳' ||
            product.strata.status === '年繳'))
      ) {
        const filtered = response.strata.filter(
          (payment) =>
            !product.strata.payments.includes(payment._id) &&
            /^管理費/.test(payment.desc)
        );
        for (let payment of filtered) {
          if (payment.amount === product.strata.fee) {
            const updated = {
              ...product.strata,
              status: '繳清',
              payments: [...product.strata.payments, payment._id],
            };
            productUpdateRequest
              .mutateAsync({ id, strata: updated })
              .then(() => {
                toast.success('繳清管理費', {
                  autoClose: 7000,
                });
                productQuery.refetch();
                return true;
              })
              .catch((err) => {
                toast.error(err, { autoClose: false });
                return false;
              });
          }
        }
      }
    }
  }
  function handleOwner() {
    const updated = [...product.contacts, ownerDialog.customer];
    productUpdateRequest.mutate(
      { id, contacts: updated },
      {
        onSuccess: () => setFileFlag(Date.now()),
      }
    );
    setOwnerDialog({ open: false });
  }
  function handleMembershipStart(date) {
    const updated = [...product.memberships];
    updated.find((m) => !m.start).start = date;
    productUpdateRequest.mutate({ id, memberships: updated });
  }
  async function handleMemo(memo) {
    try {
      if (typeof memo === 'object' && memo.content.length !== 0) {
        await productUpdateRequest.mutateAsync({
          id,
          memos: product.memos.concat(memo),
        });
      } else {
        await productUpdateRequest.mutateAsync({
          id,
          memos: product.memos.filter((m) => m._id !== memo),
        });
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <div>
      <ProductInfo
        product={product}
        contactFilter={contactFilter}
        deceasedFilter={deceasedFilter}
        selected={selected}
        deceasedControl={deceasedDialog}
        onDeleteContact={handleDeleteContact}
        setCert={setCert}
        onMembershipStart={handleMembershipStart}
      />
      <Tabs
        sx={{ mt: 2 }}
        tabs={[
          {
            title: '檔案清單',
            content: <FileTable tag={product._id} flag={fileFlag} />,
          },
          {
            title: '付費紀錄',
            content: (
              <PaymentTable
                tag={product._id}
                flag={paymentDialog.refresh}
                onLoaded={handlePaymentsLoaded}
                checkStrata={!(product.strata.status === '繳清')}
              />
            ),
          },
          {
            title: '報價單',
            content: <QuoteTable tag={product.sn} />,
          },
        ]}
      />
      <AddTaggedPaymentDialog
        state={{
          open: paymentDialog.open,
          setOpen: (open) =>
            paymentDialogDispatch({ type: open ? 'open' : 'close' }),
        }}
        contacts={product.contacts}
        tag={product._id}
        tagType="Product"
        tagLabel={product.sn}
        open={paymentDialog.open}
        onClose={() => paymentDialogDispatch({ type: 'close' })}
        onSubmit={() => {
          toast.success('成功新增付費紀錄');
          paymentDialogDispatch({ type: 'refresh' });
        }}
      />
      <AddContactDialog
        state={{ open: contactDialog, setOpen: setContactDialog }}
        onChange={handleContact}
        contactTypes={['聯絡人', '被授權人']}
      />
      <DeceasedInfoPanel control={deceasedDialog} />
      <Memos sx={{ mt: 2 }} memos={product.memos} onSubmit={handleMemo} />
      <Sidebar
        product={productQuery.data}
        state={sidebar}
        setState={setSidebar}
      />
      {product.area === '永生墓園' && (
        <AddOwnerDialog
          product={product}
          state={ownerDialog}
          setState={setOwnerDialog}
          onSave={handleOwner}
        />
      )}
      {product.area === '頂福陵園' && product.certificate?.sn && (
        <PrintableDialog open={cert} onClose={() => setCert(false)}>
          <PrintableCert products={[product]} />
        </PrintableDialog>
      )}
      <SpeedDial
        actions={[
          createAction(speedDialIcons.mainWithEdit, '編輯', () =>
            setSidebar({ ...sidebar, open: true })
          ),
          createAction(speedDialIcons.save, '儲存', () => {
            productUpdateRequest.mutate({ id, ...sidebar });
          }),
          createAction(speedDialIcons.addPayment, '新增付費紀錄', () =>
            paymentDialogDispatch({ type: 'open' })
          ),
          createAction(speedDialIcons.addDeceased, '新增使用人', () =>
            deceasedDialog[1]({
              type: 'insert',
              product: { _id: product._id, sn: product.sn },
            })
          ),
          createAction(speedDialIcons.addContact, '新增聯絡人', () =>
            setContactDialog(true)
          ),
          createAction(
            <PersonAddIcon />,
            '新增權益人',
            () => setOwnerDialog({ open: true }),
            product.area === '頂福陵園'
          ),
        ]}
      />
    </div>
  );
}

function AddOwnerDialog({ product, state, setState, onSave }) {
  const fileCreateRequest = useMutation(createFile);
  const [loading, setLoading] = React.useState(false);

  function handleContact(contact) {
    setState({ ...state, customer: contact });
  }
  function handleSave() {
    if (product.contacts.some((c) => c._id === state.customer._id)) {
      return toast.error('權益人重複', { autoClose: false });
    }
    setLoading(true);
    const files = [state.ident, state.contract, state.strata];
    const results = [];

    const names = {
      0: `身分證(${state.customer.name})`,
      1: `永生買賣合約(${product.sn})`,
      2: `頂福管理契約(${product.sn})`,
    };

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const data = new FormData();
      const ext = file.name.split('.').pop();
      data.append('file', file, encodeURI(`${names[i]}.${ext}`));
      if (i === 0) data.append('tag', state.customer._id);
      else {
        data.append('tag', state.customer._id);
        data.append('tag', product._id);
      }
      data.append('lock', true);
      results.push(fileCreateRequest.mutateAsync(data));
    }

    Promise.all(results)
      .then(() => {
        onSave();
        setLoading(false);
      })
      .catch((err) =>
        toast.error(`上傳檔案時發生錯誤：${err.toString()}`, {
          autoClose: false,
        })
      );
  }

  if (!state.customer)
    return (
      <AddContactDialog
        state={{ open: state.open, setOpen: (open) => setState({ open }) }}
        onChange={handleContact}
        contactTypes={['權益人']}
      />
    );

  return (
    <Dialog
      open={state.open}
      onClose={() => setState({ open: false })}
      maxWidth="xs"
      fullWidth>
      <DialogTitle>新增權益人</DialogTitle>
      <Grid container spacing={2} sx={{ p: 3.5, pt: 1.5 }}>
        <Grid item xs={12}>
          <Detail title="姓名" value={state.customer.name} />
          <Detail title="身分證字號" value={state.customer.ssn} />
        </Grid>
        <Grid item xs={12} sx={{ fontWeight: 'bold' }}>
          上傳相關資料：
        </Grid>
        <Grid item xs={12}>
          <UploadItem
            title="上傳身分證"
            state={state.ident}
            onChange={(file) => setState({ ...state, ident: file })}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadItem
            title="上傳永生買賣合約書"
            state={state.contract}
            onChange={(file) => setState({ ...state, contract: file })}
          />
        </Grid>
        <Grid item xs={12}>
          <UploadItem
            title="上傳頂福管理契約書"
            state={state.strata}
            onChange={(file) => setState({ ...state, strata: file })}
          />
        </Grid>
      </Grid>
      <Grid container justifyContent="flex-end" sx={{ p: 2 }}>
        <Grid item>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setState({ open: false })}>
            取消
          </Button>
          {state.customer && state.ident && state.contract && state.strata && (
            <Button
              sx={{ ml: 2 }}
              variant="contained"
              color="primary"
              onClick={handleSave}
              disabled={loading}>
              儲存
            </Button>
          )}
        </Grid>
      </Grid>
    </Dialog>
  );
}

function UploadItem({ title, state, onChange }) {
  const input = React.useRef();
  return (
    <Grid
      container
      sx={{
        cursor: 'pointer',
        ':hover': {
          textDecoration: 'underline',
        },
      }}
      onClick={() => input.current.click()}>
      <Grid item>
        <CheckCircleIcon
          sx={{
            color: (theme) =>
              state ? theme.palette.success.main : theme.palette.grey[500],
            mr: 1,
          }}
        />
      </Grid>
      <Grid item>{title}</Grid>
      <input
        ref={input}
        accept="*"
        type="file"
        onChange={(event) => onChange(event.target.files[0])}
        hidden
      />
    </Grid>
  );
}

function Sidebar({ product, state, setState }) {
  React.useEffect(() => {
    setState({
      ...state,
      sn: product.sn,
      area: product.area,
      prodType: product.prodType,
      lot: {
        ...product.lot,
      },
      strata: {
        ...product.strata,
      },
      alert: product.alert,
    });
  }, [product]);

  if (Object.keys(state).length <= 1) return null;
  return (
    <Drawer
      open={state.open}
      onClose={() => setState({ ...state, open: false })}
      anchor="right"
      sx={{
        width: 240,
        [`& .MuiDrawer-paper`]: {
          p: 2,
          width: 240,
          boxSizing: 'border-box',
        },
      }}>
      {state.area === '永生墓園' && (
        <FormControl variant="standard" sx={{ marginBottom: 2 }} fullWidth>
          <InputLabel>類別</InputLabel>
          <Select
            label="類別"
            value={state.prodType}
            onChange={(event) =>
              setState({ ...state, prodType: event.target.value })
            }>
            <MenuItem value="地面">地面</MenuItem>
            <MenuItem value="地面雙人">地面雙人</MenuItem>
            <MenuItem value="地面家族墓">地面家族墓</MenuItem>
            <MenuItem value="壁掛">壁掛</MenuItem>
            <MenuItem value="壁掛單人">壁掛單人</MenuItem>
            <MenuItem value="壁掛雙人">壁掛雙人</MenuItem>
            <MenuItem value="壁掛四人">壁掛四人</MenuItem>
            <MenuItem value="壁掛家族墓">壁掛家族墓</MenuItem>
          </Select>
        </FormControl>
      )}
      <TextField
        variant="standard"
        label="地號"
        value={state.lot.sn || ''}
        onChange={(event) =>
          setState({
            ...state,
            lot: { ...state.lot, sn: event.target.value },
          })
        }
        sx={{ mb: 2 }}
      />
      <TextField
        variant="standard"
        label={state.area === '頂福陵園' ? '坪數' : '持分'}
        value={state.lot.size || ''}
        onChange={(event) =>
          setState({
            ...state,
            lot: { ...state.lot, size: event.target.value },
          })
        }
        sx={{ mb: 3 }}
      />
      <TextField
        variant="standard"
        label="管理費"
        type="number"
        value={state.strata.fee}
        onChange={(event) =>
          setState({
            ...state,
            strata: { ...state.strata, fee: Number(event.target.value) },
          })
        }
        onBlur={() => {
          if (state.strata.fee < product.strata.fee) {
            setState((prev) => ({
              ...prev,
              strata: { ...state.strata, fee: product.strata.fee },
            }));
          }
        }}
        error={state.strata < state.strata.fee}
        sx={{ mb: 3 }}
      />
      <TextField
        variant="outlined"
        label="注意事項"
        value={state.alert}
        onChange={(event) => setState({ ...state, alert: event.target.value })}
        rows={10}
        multiline
      />
    </Drawer>
  );
}

export default ProductDetail;
