const orderUrl = '/api/event/order';
const registrationUrl = '/api/event/registration';

export const queryEventOrders = (states) => (client) => () => {
  const params = new URLSearchParams();
  for (let key of Object.keys(states)) {
    if (!states[key]) continue;
    if (key !== 'range') {
      params.append(key, states[key]);
    }
  }
  if (states.range) {
    params.append(
      'range',
      `[${states.range[0].toMillis()},${states.range[1].toMillis()}]`
    );
  }
  return client.get(
    `${orderUrl}${params.toString() ? `?${params.toString()}` : ''}`
  );
};

export const queryRegistrations = (states) => (client) => () => {
  const params = new URLSearchParams();
  for (let key of Object.keys(states)) {
    if (!states[key]) continue;
    if (key !== 'range') {
      params.append(key, states[key]);
    }
  }
  if (states.range) {
    params.append(
      'range',
      `[${states.range[0].toMillis()},${states.range[1].toMillis()}]`
    );
  }
  return client.get(
    `${registrationUrl}${params.toString() ? `?${params.toString()}` : ''}`
  );
};

export const createRegistration = (client) => (states) => {
  return client.post(`${registrationUrl}`, states);
};

export const updateRegistration = (client) => (states) => {
  return client.post(`${registrationUrl}/${states.id}`, states);
};

export const queryReceiptsByPhone = (states) => (client) => () => {
  const params = new URLSearchParams();
  params.append('phone', states.phone);
  return client.get(
    `/api/event/receipt${params.toString() ? `?${params.toString()}` : ''}`
  );
};

export const queryRegistrationsByBarcodeOrPhone =
  (states) => (client) => () => {
    const params = new URLSearchParams();
    params.append('barcode', states.barcode);
    params.append('mobile', states.mobile);
    return client.get(`${registrationUrl}/tablet?${params.toString()}`);
  };

export const serializeRegistration = (client) => (states) => {
  return client.post(`${registrationUrl}/serialize`, states, {
    timeout: 1000 * 30,
  });
};

export const sendSms = (client) => (states) => {
  return client.post('/api/event/sms', states);
};

export const printRegistration = (client) => (states) => {
  return client.post(`${registrationUrl}/print/${states.id}`, states);
};

export const getCheckStatus = (states) => (client) => () => {
  return client.get(`${registrationUrl}/check-status`);
};

export const getRequiredCheckRegistration = (states) => (client) => () => {
  return client.get(`${registrationUrl}/require-check`);
};
