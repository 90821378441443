import {
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogTitle,
  Divider,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TextField,
} from '@mui/material';
import {
  createRegistration,
  printRegistration,
  queryRegistrationsByBarcodeOrPhone,
} from '../../fetchers/event.fetch';

import { Box } from '@mui/system';
import { DateTime } from 'luxon';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { red } from '@mui/material/colors';
import { toast } from 'react-toastify';
import useHttpClient from '../../services/httpClient/useHttpClient';
import useMutation from '../../services/httpClient/useMutation';
import useQuery from '../../services/httpClient/useQuery';
import useUser from '../../services/authentication/useUser';
import { v4 as uuid } from 'uuid';

const initialShuttleStates = {
  times: ['07:30', '08:30', '10:00'],
  date: 0,
  time: '07:30',
  station: '博愛',
  party: 1,
};
function updateArray(array, index, value) {
  const newArray = [...array];
  newArray[index] = value.trim();
  return newArray;
}
function getEvent() {
  let year = DateTime.now().year - 1911;
  let event = '新春';
  const month = DateTime.now().month;

  if (month === 11 || month === 12) year = year + 1;
  if (month >= 2 && month <= 5) event = '清明';
  if (month >= 6 && month <= 9) event = '中元';
  return `${year}${event}`;
}
function AddRegistrationDialog({ open, onClose }) {
  const user = useUser();
  const SerialInput = React.useRef();
  const CustomerInput = React.useRef();
  const MobileInput = React.useRef();
  const client = useHttpClient();
  const [busy, setBusy] = React.useState(false);
  const [printDialog, setPrintDialog] = React.useState(false);
  const [state, setState] = React.useState({
    sn: '',
    barcode: '',
    mobile: '',
    selected: [],
    tabletIndex: 0,
    tablet: {},
    items: [],
    addTabletDialogOpen: false,
    receiptInfoPanelOpen: false,
    shuttleDialogOpen: false,
    shuttle: initialShuttleStates,
  });
  const [keywords, setKeywords] = React.useState();
  const tabletsQuery = useQuery(
    ['tablets', keywords],
    queryRegistrationsByBarcodeOrPhone(keywords),
    {
      enabled: !!keywords,
    }
  );
  const createRegistrationRequest = useMutation(
    createRegistration,
    {
      onSuccess: (registration) => {
        toast.success('成功新增報名紀錄');
        if (registration.registry === '現場') {
          setPrintDialog({
            open: true,
            id: registration._id,
            handleClose: () => {
              setPrintDialog({ open: false, id: null });
              handleReset();
            },
          });
        } else {
          handleReset();
        }
      },
      onSettled: () => {
        setBusy(false);
      },
    },
    { timeout: 10 * 1000 }
  );
  const event = getEvent();

  const prices = {
    專區牌位: 12000,
    特種牌位: /新春/.test(event) ? 2000 : 3000,
    甲種牌位: 2000,
    思親套組: 2200,
    '思親套組(2)': 2200,
    吉祥套組: 1500,
    如意套組: 1100,
    富貴套組: 1700,
    供燈: 300,
    光明燈: 500,
    接駁車: 500,
  };

  React.useEffect(() => {
    if (state.registry === '現場') {
      if (keywords) {
        setState((prev) => ({
          ...prev,
          name: '現場報名',
          phone: keywords.mobile,
          ...tabletsQuery.data,
        }));
      }
    } else {
      if (tabletsQuery.data) {
        setState((prev) => ({
          ...prev,
          ...tabletsQuery.data,
        }));
      }
    }
  }, [keywords, tabletsQuery.data]);
  React.useEffect(() => {
    setState({
      sn: '',
      barcode: '',
      mobile: '',
      selected: [],
      tabletIndex: 0,
      tablet: {},
      items: [],
      registry: '現場',
      addTabletDialogOpen: false,
      receiptInfoPanelOpen: false,
    });
  }, [open]);

  function handleQueryKeyDown(event) {
    event.stopPropagation();
    if (state.shuttleDialogOpen) return;
    if (event.key === 'Enter' && event.target.value.length > 0) {
      handleSearch();
    }
  }
  function handleKeyDown(event) {
    if (event.shiftKey) {
      event.preventDefault();
      switch (event.key) {
        case 'I':
          SerialInput.current.focus();
          break;
        case 'C':
          CustomerInput.current.focus();
          break;
        case 'M':
          MobileInput.current.focus();
          break;
        case 'A':
          handleAdd();
          break;
        case 'N':
          setState({ ...state, addTabletDialogOpen: true });
          break;
        case 'E':
          setState({
            ...state,
            addTabletDialogOpen: true,
            edit: { ...state.tablets[state.tabletIndex] },
          });
          break;
        case 'S':
          handleSubmit();
          break;
        default:
          break;
      }
    } else {
      if (state.shuttleDialogOpen) return;
      switch (event.key) {
        case 'ArrowUp':
          setState({
            ...state,
            tabletIndex: state.tabletIndex - 1 > 0 ? state.tabletIndex - 1 : 0,
          });
          break;
        case 'ArrowDown':
          setState({
            ...state,
            tabletIndex:
              state.tabletIndex + 1 < state.tablets.length
                ? state.tabletIndex + 1
                : state.tablets.length - 1,
          });
          break;
        case '.':
          toggle('供燈');
          break;
        case '0':
          toggle('光明燈');
          break;
        case '1':
          toggle('吉祥套組');
          break;
        case '2':
          toggle('如意套組');
          break;
        case '3':
          toggle('富貴套組');
          break;
        case '4':
          toggle('思親套組');
          break;
        case '5':
          toggle('思親套組(2)');
          break;
        case '6':
          if (state.registry === '現場') return;
          setState({ ...state, shuttleDialogOpen: true });
          break;
        case '7':
          toggle('專區牌位');
          break;
        case '8':
          toggle('特種牌位');
          break;
        case '9':
          toggle('甲種牌位');
          break;
        default:
          break;
      }
    }
  }
  function toggle(item) {
    if (state.registry === '現場' && item !== '特種牌位' && item !== '甲種牌位')
      return;
    if (state.selected.includes(item)) {
      setState({
        ...state,
        selected: state.selected.filter((x) => x !== item),
      });
    } else {
      setState({ ...state, selected: [...state.selected, item] });
    }
  }
  function handleSearch() {
    setState((prev) => {
      setKeywords({ barcode: prev.barcode, mobile: prev.mobile });
      MobileInput.current.blur();
      CustomerInput.current.blur();
      return {
        ...state,
        barcode: '',
        mobile: '',
        tabletIndex: 0,
        items: [],
      };
    });
  }
  function handleAdd() {
    if (!state.tablets || state.selected.length === 0) return;
    const items = [...state.items];
    for (let itemName of state.selected) {
      items.push({
        id: uuid(),
        event: event,
        name: itemName,
        deceaseds: [...state.tablets[state.tabletIndex].deceaseds],
        family: [...state.tablets[state.tabletIndex].family],
      });
    }
    setState({ ...state, items, selected: [] });
  }
  function handleAddShuttle(shuttle) {
    const items = [...state.items];
    items.push({
      id: uuid(),
      event: event,
      name: '接駁車',
      shuttle: {
        date: shuttle.date,
        station: shuttle.station,
        time: shuttle.time,
        party: shuttle.party,
      },
    });
    setState({ ...state, items, shuttleDialogOpen: false });
  }
  function handleSubmit() {
    if (state.items.length === 0) return toast.error('請加入品項');
    if (!state.sn) return toast.error('請輸入序號');
    if (!/(\D+)(\d+)/.test(state.sn)) return toast.error('序號格式錯誤');
    if (!state.phone) return toast.error('請輸入手機');
    if (
      state.phone.length !== 10 ||
      !['09', '02'].includes(state.phone.substring(0, 2))
    )
      return toast.error('手機格式錯誤');
    if (state.registry !== '現場' && !state._id)
      return toast.error('請選擇客戶');
    if (busy) return;

    setBusy(true);
    const payload = {
      sn: state.sn,
      user: state._id,
      items: state.items,
      name: state.name,
      phone: state.phone,
      registry: state.registry,
      created: {
        date: new Date(),
        by: {
          _id: user._id,
          name: user.name,
        },
      },
      carrier: state.carrier,
      donation: state.donation,
    };
    createRegistrationRequest.mutate(payload);
  }
  async function handleCheckCarrier() {
    try {
      if (!state.carrier || state.carrier.length !== 8)
        return setState((prev) => ({ ...prev, carrier: '' }));
      await client.post(
        `${process.env.REACT_APP_API_URL}/api/receipt/carrier`,
        { carrier: state.carrier }
      );
    } catch (error) {
      setState((prev) => ({ ...prev, carrier: '' }));
    }
  }
  async function handleCheckDonation() {
    try {
      if (
        !state.donation ||
        state.donation.length < 3 ||
        state.donation.length > 7
      )
        return setState((prev) => ({ ...prev, donation: '' }));
      await client.post(
        `${process.env.REACT_APP_API_URL}/api/receipt/donation`,
        { donation: state.donation }
      );
    } catch (error) {
      setState((prev) => ({ ...prev, donation: '' }));
    }
  }
  function handleDragOver(e, index) {
    if (state.tablets[index].dragged) e.preventDefault();
  }
  function handleDrag(index, field, position, value) {
    const updatedTablets = [...state.tablets];
    const update = updatedTablets[index];
    update.dragged = true;
    update.start = {
      field,
      position,
      value,
    };
    setState({ ...state, tablets: updatedTablets });
  }
  function handleDrop(index, field, position, value) {
    const updatedTablets = [...state.tablets];
    const update = updatedTablets[index];
    update.dragged = undefined;
    const original = update.start;
    update[original.field][original.position] = value;
    update[field][position] = original.value;
    update.start = undefined;
    setState({ ...state, tablets: updatedTablets });
  }
  function handleReset() {
    const result = state.sn.match(/(\D+)(\d+)/);
    const prefix = result[1];
    const digits = result[2];
    const newSerial =
      prefix + (parseInt(digits) + 1).toString().padStart(digits.length, '0');
    setState({
      sn: newSerial,
      barcode: '',
      mobile: '',
      selected: [],
      tabletIndex: 0,
      tablet: {},
      items: [],
      registry: state.registry,
      addTabletDialogOpen: false,
      receiptInfoPanelOpen: false,
    });
    setBusy(false);
  }

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      maxWidth="lg"
      onKeyDown={handleKeyDown}
      fullWidth>
      <DialogTitle
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
        }}>
        <span>
          新增報名紀錄
          <Chip
            label={event}
            variant="outlined"
            color="primary"
            sx={{ ml: 1 }}
          />
        </span>
        {busy && <CircularProgress size={25} />}
      </DialogTitle>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={3}>
            <FormControl size="small" fullWidth>
              <InputLabel>報名方式</InputLabel>
              <Select
                label="報名方式"
                value={state.registry}
                onChange={(event) =>
                  setState({ ...state, registry: event.target.value })
                }
                size="large">
                <MenuItem value="現場">現場</MenuItem>
                <MenuItem value="劃撥">劃撥</MenuItem>
                <MenuItem value="台北">台北</MenuItem>
                <MenuItem value="林口">林口</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputRef={SerialInput}
              variant="outlined"
              label="回條序號(I)"
              value={state.sn}
              onChange={(event) =>
                setState({ ...state, sn: event.target.value })
              }
              onKeyDown={(event) => event.stopPropagation()}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputRef={CustomerInput}
              variant="outlined"
              label="客戶編號(C)"
              value={state.barcode}
              onChange={(event) => {
                const input = event.target.value;
                if (input.length <= 6)
                  setState((prev) => ({ ...prev, barcode: input }));
                if (input.length === 6) handleSearch();
              }}
              onKeyDown={handleQueryKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <TextField
              inputRef={MobileInput}
              variant="outlined"
              label="手機(M)"
              value={state.mobile}
              onChange={(event) => {
                const input = event.target.value;
                if (input.length <= 10)
                  setState((prev) => ({ ...prev, mobile: input }));
                if (input.length === 10) handleSearch();
              }}
              onKeyDown={handleQueryKeyDown}
              fullWidth
            />
          </Grid>
        </Grid>
      </Box>
      <Box sx={{ p: 2 }}>
        <Divider sx={{ mb: 4 }} />
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="姓名"
              value={state.name || ''}
              inputProps={{ readOnly: true }}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="手機"
              value={state.phone || ''}
              onChange={(event) =>
                setState({ ...state, phone: event.target.value })
              }
              onKeyDown={handleQueryKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <div
              style={{
                position: 'relative',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: 58,
              }}>
              <div style={{ position: 'absolute', left: 0, top: 0 }}>
                總金額
              </div>
              <div style={{ fontSize: 30 }}>
                {!state.items
                  ? '0'
                  : `$${state.items
                      .reduce((sum, item) => {
                        if (item.name !== '接駁車')
                          return sum + prices[item.name];
                        return sum + prices[item.name] * item.shuttle.party;
                      }, 0)
                      .toLocaleString()}`}
              </div>
            </div>
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="載具"
              value={state.carrier || ''}
              onChange={(event) =>
                setState({
                  ...state,
                  donation: '',
                  carrier: event.target.value,
                })
              }
              onBlur={handleCheckCarrier}
              onKeyDown={handleQueryKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              variant="filled"
              label="捐贈碼"
              value={state.donation || ''}
              onChange={(event) =>
                setState({
                  ...state,
                  carrier: '',
                  donation: event.target.value,
                })
              }
              onBlur={handleCheckDonation}
              onKeyDown={handleQueryKeyDown}
              fullWidth
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={12} sm={4}>
            <KeyPad selected={state.selected} />
          </Grid>
          <Grid item xs={12} sm={8}>
            <Box
              sx={{
                border: '1px solid rgba(0,0,0,0.2)',
                borderRadius: 1,
                minHeight: 290,
              }}>
              <Table sx={{ 'th,td': { fontSize: 16 } }}>
                <TableHead>
                  <TableRow>
                    <TableCell>歿者</TableCell>
                    <TableCell>歿者(右)</TableCell>
                    <TableCell>陽上1</TableCell>
                    <TableCell>陽上2</TableCell>
                    <TableCell>陽上3</TableCell>
                    <TableCell>陽上4</TableCell>
                    <TableCell>陽上5</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.tablets &&
                    state.tablets.map((tablet, index) => (
                      <TableRow
                        key={tablet.deceaseds.join() + tablet.family.join()}
                        sx={{
                          bgcolor:
                            state.tabletIndex === index
                              ? red[50]
                              : 'transparent',
                        }}>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'deceaseds',
                                0,
                                tablet.deceaseds.at(0)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'deceaseds',
                                0,
                                tablet.deceaseds.at(0)
                              )
                            }>
                            {tablet.deceaseds.at(0) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'deceaseds',
                                1,
                                tablet.deceaseds.at(1)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'deceaseds',
                                1,
                                tablet.deceaseds.at(1)
                              )
                            }>
                            {tablet.deceaseds.at(1) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'family',
                                0,
                                tablet.family.at(0)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'family',
                                0,
                                tablet.family.at(0)
                              )
                            }>
                            {tablet.family.at(0) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'family',
                                1,
                                tablet.family.at(1)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'family',
                                1,
                                tablet.family.at(1)
                              )
                            }>
                            {tablet.family.at(1) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'family',
                                2,
                                tablet.family.at(2)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'family',
                                2,
                                tablet.family.at(2)
                              )
                            }>
                            {tablet.family.at(2) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'family',
                                3,
                                tablet.family.at(3)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'family',
                                3,
                                tablet.family.at(3)
                              )
                            }>
                            {tablet.family.at(3) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                        <TableCell>
                          <span
                            draggable
                            onDragOver={(e) => handleDragOver(e, index)}
                            onDrop={() =>
                              handleDrop(
                                index,
                                'family',
                                4,
                                tablet.family.at(4)
                              )
                            }
                            onDragStart={() =>
                              handleDrag(
                                index,
                                'family',
                                4,
                                tablet.family.at(4)
                              )
                            }>
                            {tablet.family.at(4) || (
                              <div
                                style={{
                                  width: '50px',
                                  height: '20px',
                                }}></div>
                            )}
                          </span>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </Box>
          </Grid>
          <Grid item xs={12}>
            {state.items?.map((item, index) =>
              item.name !== '接駁車' ? (
                <Box
                  sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.2)',
                    borderRadius: 1,
                    position: 'relative',
                    mb: 2,
                  }}>
                  <IconButton
                    sx={{ position: 'absolute', top: 5, right: 10 }}
                    onClick={() =>
                      setState({
                        ...state,
                        items: state.items.filter((i) => i.id !== item.id),
                      })
                    }>
                    <DeleteIcon sx={{ fontSize: 28 }} />
                  </IconButton>
                  <Grid container spacing={3}>
                    <Grid item xs={2.3}>
                      <img
                        width="100%"
                        src={`https://dinfucemstorage.blob.core.windows.net/image/dinfucem/${item.name.replace(
                          '(2)',
                          ''
                        )}.jpg`}
                        alt=""
                      />
                    </Grid>
                    <Grid item xs={9.7}>
                      <div
                        style={{
                          fontSize: 25,
                          color: 'rgba(0,0,0,0.85)',
                        }}>
                        {item.name}
                        <span style={{ marginLeft: 10 }}>
                          ${prices[item.name].toLocaleString()}
                        </span>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <span
                          style={{
                            color: 'rgb(113, 12, 16)',
                            marginRight: 10,
                          }}>
                          逝
                        </span>
                        {item.deceaseds.join(' ')}
                      </div>
                      <div style={{ marginTop: 10 }}>
                        <span
                          style={{
                            color: 'rgb(232, 122, 41)',
                            marginRight: 10,
                          }}>
                          陽
                        </span>
                        {item.family.join(' ')}
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              ) : (
                <Box
                  sx={{
                    p: 2,
                    border: '1px solid rgba(0,0,0,0.2)',
                    borderRadius: 1,
                    position: 'relative',
                    mb: 2,
                  }}>
                  <IconButton
                    sx={{ position: 'absolute', top: 5, right: 10 }}
                    onClick={() =>
                      setState({
                        ...state,
                        items: state.items.filter((i) => i.id !== item.id),
                      })
                    }>
                    <DeleteIcon sx={{ fontSize: 28 }} />
                  </IconButton>
                  <Grid container spacing={3}>
                    <Grid item xs={2.3}>
                      <Box
                        sx={{
                          border: '1px dashed rgba(0,0,0,0.2)',
                          height: 100,
                          width: 100,
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          color: 'rgba(0,0,0,0.7)',
                        }}>
                        接駁車
                      </Box>
                    </Grid>
                    <Grid item xs={9.7}>
                      <div style={{ fontSize: 25, color: 'rgba(0,0,0,0.85)' }}>
                        {item.name}
                        <span style={{ marginLeft: 10 }}>
                          $
                          {(
                            prices[item.name] * item.shuttle.party
                          ).toLocaleString()}
                        </span>
                      </div>
                      <div style={{ marginTop: 10 }}>
                        {item.shuttle.date === 0 ? '第一天' : '第二天'} -{' '}
                        {item.shuttle.station} - {item.shuttle.time} -{' '}
                        {item.shuttle.party}人
                      </div>
                    </Grid>
                  </Grid>
                </Box>
              )
            )}
          </Grid>
        </Grid>
      </Box>
      <AddTabletDialog
        open={state.addTabletDialogOpen}
        edit={state.edit}
        onClose={() =>
          setState((prev) => ({
            ...prev,
            addTabletDialogOpen: false,
            edit: null,
          }))
        }
        onSave={(tablet) =>
          setState((prev) => ({
            ...prev,
            tablets: [tablet, ...(prev?.tablets || [])],
            edit: null,
            tabletIndex: 0,
          }))
        }
      />
      <ShuttleDialog
        open={state.shuttleDialogOpen}
        onAdd={handleAddShuttle}
        onClose={() =>
          setState({
            ...state,
            shuttleDialogOpen: false,
          })
        }
      />
      {printDialog.open && (
        <PrintDialog
          id={printDialog.id}
          onClose={() => {
            printDialog.handleClose();
            handleReset();
          }}
        />
      )}
    </Dialog>
  );
}
function AddTabletDialog({ open, edit, onClose, onSave }) {
  const [tablet, setTablet] = React.useState({ deceaseds: [], family: [] });

  React.useEffect(() => {
    if (edit) {
      setTablet(edit);
    }
  }, [edit]);

  function handleSave() {
    let deceaseds = tablet.deceaseds.filter((d) => d);
    let family = tablet.family.filter((d) => d);
    if (deceaseds.length === 0 && family.length === 0) {
      return;
    }
    onSave({ deceaseds, family });
    handleClose();
  }
  function handleClose() {
    onClose();
    setTablet({ deceaseds: [], family: [] });
  }

  return (
    <Dialog open={open} maxWidth="md" onClose={handleClose} fullWidth>
      <DialogTitle>新增牌位</DialogTitle>
      <Box p={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="歿者(左)"
              size="large"
              value={tablet.deceaseds.at(0)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  deceaseds: updateArray(
                    tablet.deceaseds,
                    0,
                    event.target.value
                  ),
                })
              }
              inputProps={{
                style: {
                  fontSize: 20,
                  fontWeight: 'bold',
                },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              autoFocus
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="歿者(右)"
              size="large"
              value={tablet.deceaseds.at(1)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  deceaseds: updateArray(
                    tablet.deceaseds,
                    1,
                    event.target.value
                  ),
                })
              }
              inputProps={{
                style: { fontSize: 20, fontWeight: 'bold' },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mt: 3 }}>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="陽上1"
              size="large"
              value={tablet.family.at(0)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  family: updateArray(tablet.family, 0, event.target.value),
                })
              }
              inputProps={{
                style: { fontSize: 20, fontWeight: 'bold' },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="陽上2"
              size="large"
              value={tablet.family.at(1)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  family: updateArray(tablet.family, 1, event.target.value),
                })
              }
              inputProps={{
                style: { fontSize: 20, fontWeight: 'bold' },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="陽上3"
              size="large"
              value={tablet.family.at(2)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  family: updateArray(tablet.family, 2, event.target.value),
                })
              }
              inputProps={{
                style: { fontSize: 20, fontWeight: 'bold' },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="陽上4"
              size="large"
              value={tablet.family.at(3)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  family: updateArray(tablet.family, 3, event.target.value),
                })
              }
              inputProps={{
                style: { fontSize: 20, fontWeight: 'bold' },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} md={4}>
            <TextField
              variant="standard"
              label="陽上5"
              size="large"
              value={tablet.family.at(4)}
              onChange={(event) =>
                setTablet({
                  ...tablet,
                  family: updateArray(tablet.family, 4, event.target.value),
                })
              }
              inputProps={{
                style: { fontSize: 20, fontWeight: 'bold' },
              }}
              InputLabelProps={{ style: { fontSize: 16 } }}
              fullWidth
            />
          </Grid>
          <Grid item xs={12} sx={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              size="large"
              sx={{ mb: 2 }}
              onClick={handleSave}>
              新增
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}
function KeyPadItem({ item, title, shortcut, selected, xs, disabled }) {
  return (
    <Grid item xs={xs || 4}>
      <div
        style={{
          border: '1px solid rgba(0,0,0,0.2)',
          borderRadius: 5,
          backgroundColor: selected.includes(item)
            ? 'rgba(255,0,0,0.55)'
            : 'rgba(0,0,0,0.05)',
          color: disabled
            ? 'rgba(0,0,0,0.3)'
            : selected.includes(item)
            ? 'rgb(255,255,255)'
            : 'rgba(0,0,0,0.7)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: 60,
          fontWeight: 'bold',
        }}>
        {title}
        {shortcut && (
          <span
            style={{
              color: selected.includes(item)
                ? 'rgba(255,255,255)'
                : 'rgba(0,0,0,0.5)',
              marginLeft: 2,
            }}>
            ({shortcut})
          </span>
        )}
      </div>
    </Grid>
  );
}
function KeyPad({ selected }) {
  return (
    <Box>
      <Grid container spacing={2}>
        <KeyPadItem
          item="專區牌位"
          title="專區"
          shortcut="7"
          selected={selected}
        />
        <KeyPadItem
          item="特種牌位"
          title="特種"
          shortcut="8"
          selected={selected}
        />
        <KeyPadItem
          item="甲種牌位"
          title="甲種"
          shortcut="9"
          selected={selected}
        />
        <KeyPadItem
          item="思親套組"
          title="思親Ⅰ"
          shortcut="4"
          selected={selected}
        />
        <KeyPadItem
          item="思親套組(2)"
          title="思親Ⅱ"
          shortcut="5"
          selected={selected}
        />
        <KeyPadItem
          item="接駁車"
          title="接駁車"
          shortcut="6"
          selected={selected}
        />
        <KeyPadItem
          item="吉祥套組"
          title="吉祥"
          shortcut="1"
          selected={selected}
        />
        <KeyPadItem
          item="如意套組"
          title="如意"
          shortcut="2"
          selected={selected}
        />
        <KeyPadItem
          item="富貴套組"
          title="富貴"
          shortcut="3"
          selected={selected}
        />
        <KeyPadItem
          item="光明燈"
          xs={8}
          title="光明燈"
          shortcut="0"
          selected={selected}
        />
        <KeyPadItem item="供燈" title="供燈" shortcut="." selected={selected} />
      </Grid>
    </Box>
  );
}
function ShuttleDialog({ open, onClose, onAdd }) {
  const [state, setState] = React.useState(initialShuttleStates);
  function changeStation(event) {
    let times = ['07:30', '08:30', '10:00'];
    let time = state.time;
    if (event.target.value === '桃園') {
      times = ['08:00'];
    }
    if (!times.includes(state.time)) {
      time = times[0];
    }
    setState({
      ...state,
      station: event.target.value,
      times: times,
      time: time,
    });
  }
  function handleAdd() {
    onAdd(state);
    setState(initialShuttleStates);
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>接駁車</DialogTitle>
      <Grid container sx={{ padding: 2 }} spacing={2}>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>日期</InputLabel>
            <Select
              value={state.date}
              label="日期"
              onChange={(event) =>
                setState({
                  ...state,
                  date: event.target.value,
                })
              }>
              <MenuItem value={0}>第一天</MenuItem>
              <MenuItem value={1}>第二天</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>接駁站</InputLabel>
            <Select
              value={state.station}
              label="接駁站"
              onChange={changeStation}>
              <MenuItem value="博愛">博愛</MenuItem>
              <MenuItem value="行天宮">行天宮</MenuItem>
              <MenuItem value="板橋">板橋</MenuItem>
              <MenuItem value="桃園">桃園</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <InputLabel>時間</InputLabel>
            <Select
              value={state.time}
              label="時間"
              onChange={(event) =>
                setState({
                  ...state,
                  time: event.target.value,
                })
              }>
              {state.times.map((time, index) => (
                <MenuItem id={index} value={time}>
                  {time}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            type="number"
            label="人數"
            value={state.party}
            onChange={(event) => {
              setState((prev) => ({
                ...prev,
                party: event.target.value,
              }));
            }}
            onBlur={(event) => {
              if (event.target.value === null || event.target.value < 1)
                setState((prev) => ({ ...prev, party: 1 }));
            }}
            fullWidth
          />
        </Grid>
        <Grid item xs={12} sx={{ textAlign: 'right' }}>
          <Button
            variant="contained"
            color="primary"
            size="large"
            onClick={handleAdd}>
            確定
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
}
function PrintDialog({ id, onClose }) {
  const printRegistrationRequest = useMutation(printRegistration, {
    onSuccess: () => {
      toast.success('開始列印...');
      onClose();
    },
  });
  function handlePrint() {
    printRegistrationRequest.mutate({ id });
  }
  return (
    <Dialog open={true} maxWidth="sm" fullWidth>
      <DialogTitle>是否直接列印？</DialogTitle>
      <Box sx={{ m: 3 }}>
        <Grid container spacing={2}>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="warning"
              size="large"
              onClick={() => onClose()}
              fullWidth>
              不要列印
            </Button>
          </Grid>
          <Grid item xs={6} sx={{ textAlign: 'center' }}>
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={handlePrint}
              fullWidth>
              列印
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Dialog>
  );
}

export default AddRegistrationDialog;
